const ExploreCard = ({props, setOpen}) => {
    const openModal  = () => {
        setOpen({
            open: true,
            img: props.img,
            title: props.title,
            sub: props.disc,
            disc: props.detail,
            url: props.url
        })
    }
  return (
    <div className='mx-auto text-th-white border border-th-white w-[280px] md:w-[300px] h-[370px] md:h-[450px] grid items-stretch justify-items-center'>
    <img src={props.img} alt={props.title} className="object-cover h-[200px] lg:h-[270px] w-full" />
    <div className='w-full h-full p-3 flex flex-col justify-between items-center'>
    <h3 className='font-secondary text-lg mb-3'>{props.title}</h3>
    <p className='font-primary mb-5 text-sm font-light text-center'>{props.disc}</p>
    <div className='self-end'>
    <button onClick={openModal} className='font-primary tracking-widest flex items-center text-sm md:text-base space-x-3 md:space-x-5 '>
    MORE
    <svg width="38" height="10" className='w-8 md:-12 ' viewBox="0 0 38 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.4312 5L33.1011 0.669873L28.771 5L33.1011 9.33013L37.4312 5ZM0.383301 5.75H33.1011V4.25H0.383301V5.75Z" fill="white"/>
    </svg>
    </button>
    </div>
    </div>
    </div>
  )
}

export default ExploreCard