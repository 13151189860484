import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Rooms from "../components/accommodation/Rooms"
import Suites from "../components/accommodation/Suites"
import RoomCard from "../components/accommodation/RoomCard"
import rooms from '../accommodationData.json'
import suites from '../suites.json'
import Symphony from "../components/accommodation/Symphony"
import symphony from '../symphony-place.json'
import Header from "../components/SEO/Header"

const AccommodationPage = () => {
  return (
    <>
    <Header subtitle={"Accommodation"} />
    <div className='overflow-hidden'>
    <div className="bg-th-gray min-h-screen w-screen overflow-hidden">
    <Navbar />
    <div className={`bg-accommodation w-screen h-[210px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>Accommodation</h1>
    </div>
      <Rooms />
      {rooms.map(room => (
      <RoomCard props={room} key={room.id} />
      ))
      }
      <Suites />
      {suites.map(room => (
      <RoomCard props={room} key={room.id} />
      ))
      }
      <Symphony />
      {symphony.map(room => (
      <RoomCard props={room} key={room.id} />
      ))
      }
    <Footer />
    </div>
    </div>
    </>
  )
}

export default AccommodationPage