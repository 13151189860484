import React from 'react'
import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import MapSection from '../components/contact/MapSection'
import ContactCards from '../components/contact/ContactCards'
import Header from '../components/SEO/Header'

const ContactPage = () => {
  return (
    <>
    <Header subtitle={"Contact"} />
    <div className="overflow-hidden">
    <div className="bg-th-gray min-h-screen w-screen">
    <Navbar />
    <div className={`bg-contact w-screen h-[210px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>Contact</h1>
    </div>

    <MapSection />
    <ContactCards />
    <Footer />
    </div>
    </div>
    </>
  )
}

export default ContactPage