import React from 'react'

const ServicesLine = () => {
  return (
    <div className='w-screen h-[300px] md:h-[450px] bg-about-us bg-center bg-no-repeat bg-cover'>
        <div className='w-full md:w-1/2 h-full bg-th-black bg-opacity-50 flex flex-col justify-center items-center gap-4 lg:gap-8'>
            <img src='/assets/images/logo.png' alt="moda_logo" className='w-20 h-20 lg:w-24 lg:h-24' />
            <p className='font-primary text-center px-5 lg:px-20 xl:px-36 text-base lg:text-lg tracking-wide leading-normal lg:leading-relaxed xl:leading-loose'>
            With a distinctively fresh 2.0 perspective, we offer you all the essentials to stay connected. Designed for modern comfort and to keep you well-serviced, Moda Hotel offers a portfolio of hotel privileges.
            Amenities and services to make your stay in our Vancouver hotel more posh!
            </p>
        </div>
    </div>
  )
}

export default ServicesLine