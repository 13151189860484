import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Card from "../components/services/Card"
import servicesData from '../services'
import ServicesLine from "../components/services/ServicesLine"
import Header from '../components/SEO/Header'

const Services = () => {
  return (
    <>
    <Header subtitle={"Services"} />
    <div className='overflow-hidden'>
    <div className="bg-th-gray text-th-white min-h-screen w-screen">
    <Navbar />
    <div className={`bg-about-us w-screen h-[210px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>Services</h1>
    </div>
    <section className="my-16">
    <div className="w-full md:w-11/12 mx-auto bg-th-white">
     <div className=" bg-th-primary py-10 md:py-14 lg:py-16 w-9/12 lg:w-1/2 mx-auto relative -top-8">
        <h3 className="font-secondary text-xl md:text-2xl lg:text-3xl text-center tracking-wider">AMENITIES AND SERVICES</h3>
     </div>
     <div className="px-4 md:px-10 py-5 md:py-10 mx-auto">
         <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-16">
         {servicesData.services.map((service, i) => (
            <Card key={i} service={service} />
         ))}
         </div>
     </div>
    </div>
    </section>
    <section className="mb-20">
      <ServicesLine />
    </section>
    <Footer />
    </div>
    </div>
    </>
  )
}

export default Services