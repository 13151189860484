import React from 'react'
import { Link } from 'react-router-dom'

const Amenities = ({amenities}) => {
  return (
    <div>
        <div className='w-screen mt-20'>
            <h2 className='bg-th-primary bg-opacity-70 py-5 md:py-7 w-fit pl-10 md:pl-40 pr-2 md:pr-5 font-secondary tracking-wider md:tracking-widest text-xl md:text-2xl'>AMENITIES</h2>
        </div>
        <div className=' bg-th-white w-11/12 lg:w-10/12 xl:w-9/12 h-auto lg:h-[500px] xl:h-[440px] px-3 md:px-7 xl:px-10 2xl:px-20 mx-auto flex flex-col justify-around'>
        <div className='grid grid-rows-3 grid-cols-2 md:grid-cols-11 md:grid-flow-col gap-1 lg:gap-2 text-th-gray justify-items-center pt-5 md:pt-10'>
        {amenities.map((amenity, i) => {
            if((i+1) % 3 === 0) {
                return( 
                    <> 
                    <div className='col-span-1 md:col-span-2 flex flex-col justify-center items-center space-y-2 py-1'>     
                    <img src={amenity[1]} alt={amenity[0]} className="w-10 h-10" />
                    <p className='text-th-gray font-primary text-center font-sm lg:font-base'>{amenity[0]}</p>
                    </div>
                    <div class="hidden md:block col-span-1 justify-self-center self-center row-span-full h-1/2 w-0.5 bg-th-gray"></div> 
                    </>
                )
              }else { 
                     return(
                    <div className='col-span-1 md:col-span-2 flex flex-col justify-center items-center space-y-2 py-1'>     
                    <img src={amenity[1]} alt={amenity[0]} className="w-10 h-10" />
                    <p className='text-th-gray font-primary text-center font-sm lg:font-base'>{amenity[0]}</p>
                    </div>
                    )

            }
        }
        )}
        </div>
        <Link to="/services" className='text-th-gray text-center md:text-right font-primary pt-12 md:pt-0 pb-3 cursor-pointer hover:text-th-primary'>View full amenities & services available.</Link>
        </div>
    </div>
  )
}

export default Amenities