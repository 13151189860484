import React from 'react'

const MapSection = () => {
  return (
    <div className='w-screen mt-0 md:mt-10 mx-auto flex md:flex-row flex-col-reverse justify-between items-end md:items-start space-x-10 text-th-white'>
        <div className='w-screen lg:w-2/3 ml-0 lg:ml-10 h-[200px] lg:h-[500px]'>
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.838165901543!2d-123.12261488408622!3d49.27946367891923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486717e243f711b%3A0x105e9fc91383ade8!2sModa%20Hotel!5e0!3m2!1sen!2sca!4v1654891782662!5m2!1sen!2sca"
        className='w-full h-full grayscale'
        loading="lazy" referrerPolicy="no-referrer-when-downgrade"
        title="modahotelmap"></iframe>
        </div>
        <div className='w-full lg:w-1/3'>
            <div className="bg-th-secondary text-center md:text-left pr-0 md:pr-40 py-4 md:py-7 xl:py-10 pl-0 md:pl-10">
                <p className='font-primary text-th-gray tracking-wider font-bold mb-2 text-sm md:text-base'>GET CONNECTED</p>
                <h1 className='font-secondary text-3xl md:text-4xl xl:text-5xl tracking-widest md:tracking-wider'>MODA<br /> HOTEL</h1>
            </div>
            <p className='mx-auto mt-5 lg:mt-8 mb-5 lg:mb-0 font-primary text-base xl:text-lg font-light w-11/12'>
                We believe that a more connected world makes the world a friendlier place.<br />
                Connect with us in real-time for news, updates and special promotions and feel free to drop us a line!
            </p>
        </div>

    </div>
  )
}

export default MapSection