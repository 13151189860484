import React from 'react'
import { Helmet } from 'react-helmet'

const Header = ({subtitle}) => {
  return (
    <Helmet>
    <meta charset="utf-8" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#3E3E3E" />
    <meta
      name="description"
      content="Moda Hotel, a downtown Vancouver hotel, offering a unique hotel experience for people from all over the world to enjoy. With its hip and historic allure, Moda offers 69 chic guestrooms and suites and an inspiring collection of establishments including the award-winning Cibo Trattoria and Uva Wine Bar, the stylish Red Card Sports Bar and specialty beer and wine store, Viti Wine and Lagers."
    />
    <link rel="apple-touch-icon" href="/logo192.png" />
    <link rel="manifest" href="/manifest.json" />
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous" />
    
    <title>Moda Hotel &nbsp;| &nbsp;  {subtitle && subtitle}</title>

    </Helmet>
  )
}

export default Header