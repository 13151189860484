import React from 'react'
import SubTitle from './SubTitle'

const Rooms = () => {
  return (
    <div className="mt-5 mb-10 lg:my-10 text-th-white">
    <SubTitle title={'rooms'} />
    <div className='w-11/12 lg:w-9/12 mx-auto my-8 lg:my-10'>
    <h4 className='text-lg lg:text-xl font-secondary text-justify leading-4 md:leading-tight md:text-center'>
    Get cozy in a stylish haven that is yours to enjoy
    </h4>
    <p className='font-primary leading-relaxed lg:leading-none mt-4 md:pt-3 text-sm lg:text-base'>
    Designed by Vancouver's top interior designers, Moda Hotel Vancouver's guestrooms and suites have been revitalized to become hip and historic in every sense. With red accents and trendy furnishings designed for your comfort, this Vancouver hotel offers spirited urban chic accommodations that tantalize the senses and are truly one-of a kind. Experience Moda.
    </p>
    </div>
    </div>
  )
}

export default Rooms