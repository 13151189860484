import React from 'react'

const DinningCard = ({props}) => {
  return (
    <div className="w-full text-th-gray overflow-hidden">
    <div>
    <img src={props.photo} alt="dinningimg" className="object-cover mx-auto object-center xl:object-bottom shadow-md w-full h-64 md:w-9/12 md:h-80 lg:w-[350px] lg:h-[400px] xl:w-[380px] xl:h-[400px]" />    
     <div className="relative px-0 xl:px-4 left-1/2 -translate-x-1/2">
       <div className="bg-th-white flex flex-col justify-between mx-auto w-full lg:w-[350px] lg:h-[350px] xl:w-[380px] p-3 xl:p-5 shadow-lg mih-h-[150px] lg:min-h-[320px]">
          <div className="uppercase text-xs font-semibold font-primary tracking-wider pt-2 lg:pt-0">
         {props.sub_title}
        </div>
        <h4 className="mt-2 text-2xl tracking-wide font-semibold uppercase leading-tight font-secondary">{props.name}</h4>
      <div className="mt-1 md:mt-4">
        <span className="text-sm xl:text-base font-primary text-ellipsis overflow-hidden">{props.detail.length > 200 ? props.detail.slice(0, 200).concat('...') : props.detail}</span>
      </div> 
      <div className='flex justify-end pt-3 xl:pt-2'>
      <a rel="noreferrer" className='bg-th-secondary hover:bg-th-primary text-th-white font-secondary py-2 px-5 tracking-wider text-xs md:text-sm' target="_blank" href={props.url}>MORE</a>
      </div>
      </div>
     </div>
      
    </div>
      </div>
  )
}

export default DinningCard