import React from 'react'

const Pictures = () => {
  return (
   <section className='w-full mx-auto h-[300px] lg:h-[600px] grid grid-cols-3 grid-rows-2 items-stretch py-10 lg:py-20'>
       <img src="/assets/images/events/1.jpg" alt="event_image" className='w-full h-full object-cover' />
       <img src="/assets/images/events/2.jpg" alt="event_image" className='w-full h-full object-cover' />
       <img src="/assets/images/events/3.jpg" alt="event_image" className='w-full h-full object-cover' />
       <img src="/assets/images/events/3.jpg" alt="event_image" className='w-full h-full object-cover' />
       <img src="/assets/images/events/1.jpg" alt="event_image" className='w-full h-full object-cover' />
       <img src="/assets/images/events/2.jpg" alt="event_image" className='w-full h-full object-cover' />
   </section>
  )
}

export default Pictures