import React from 'react'
import SubTitle from './SubTitle'

const Symphony = () => {
  return (
    <div className="pt-24 mb-10 lg:pt-16 text-th-white">
    <SubTitle title={'Moda series at symphony place'} />
    </div>
  )
}

export default Symphony