import React from 'react'

const Meeting = () => {
  return (
    
    <section className= "pt-8 pb-14 md:py-20 text-th-white w-screen">
    <div className="flex flex-col-reverse md:flex-row justify-center items-center  space-x-0 md:space-x-5 lg:space-x-16 xl:space-x-28 ">
        <div className="w-11/12 md:w-1/2 mx-0 md:mx-auto pt-10 md:pt-0 pl-0 md:pl-10 lg:pl-20 xl:pl-40">
            <h3 className="font-secondary text-3xl md:text-4xl tracking-wider pb-5 text-center md:text-left">MEETING ROOM</h3>
            <div className=" bg-th-gray border border-th-white p-5">
                <p className=" font-primary text-sm md:text-base lg:text-lg font-light">Coming soon....</p>
                {/* <div className='py-5 font-primary font-light text-sm'>
                <p>- Business</p>
                <p>- Gatherings</p>
                <p>- Meet-ups</p>
                </div> */}
                <h5 className=" font-secondary text-lg tracking-wider mt-5">CONTACT</h5>
                <p className="font-primary font-light">By e-mail: <a href="mailto:reservations@modahotel.ca">reservations@modahotel.ca</a></p>
            </div>
        </div>
        <img src="/assets/images/events/3.jpg" alt="meetingroom_img" className="w-full md:w-1/2 h-[300px] md:h-[500px] object-cover" />
    </div>
    </section>

  )
}

export default Meeting