import SubFooter from "./SubFooter"

export const Footer = () => {
  return (
  <footer className="w-screen mx-auto">
  <div className=" bg-th-primary bg-opacity-80 text-th-white font-primary">
  <div className="max-w-[1440px] flex flex-col gap-5 lg:gap-0 lg:flex-row w-full xl:w-11/12 mx-auto px-5 py-5 lg:p-10 items-center lg:items-start justify-center lg:justify-between">
  <div className="flex flex-col items-center gap-2">
  <a href="https://www.modahotel.ca/">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="w-24 h-24 lg:w-32 lg:h-32"
          />
        </a>
        <a className="link link-hover text-sm lg:text-base text-center md:text-left tracking-wide pl-0 md:pl-8" href='https://goo.gl/maps/Ff4nJbMAAqyna7P56' target="_blank" rel="noreferrer">900 Seymour Street <br/> Vancouver, BC <br/>V6B 3L9</a> 
  </div> 
  <div className="mt-0 lg:mt-10 flex flex-col items-center lg:items-start gap-0.5">
    <span className="font-secondary text-th-white opacity-100 tracking-wider lg:tracking-widest text-lg md:text-xl pb-1 lg:pb-3">CONTACT</span>  
       <a className="link link-hover text-sm lg:text-base mt-1" href='tel:+1 604 683 4251'>+1 604.683.4251</a> 
    <a className="link link-hover text-sm lg:text-base mt-1" href='mailto:info@modahotel.ca'>info@modahotel.ca</a>

  </div>
  <div className="mt-0 lg:mt-10 flex flex-col items-center lg:items-start gap-1">
    <span className="font-secondary text-th-white opacity-100 tracking-wider lg:tracking-widest text-lg md:text-xl pb-1 lg:pb-3">FOLLOW US</span> 
    <div className="flex gap-4">
    <a target="_blank" rel="noreferrer" href="https://twitter.com/ModaHotel" className="link link-hover">
    <svg className="w-5 h-5 lg:w-6 lg:h-7 fill-th-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
</svg>
  </a> 
    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/ModaHotel" className="link link-hover"><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16" className="w-5 h-5 lg:w-7 lg:h-7 fill-th-white">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
</svg></a> 
    <a target="_blank" rel="noreferrer" href="https://www.pinterest.ca/viaggiohospitality/" className="link link-hover"><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16" className="w-5 h-5 lg:w-7 lg:h-7 fill-th-white">
  <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"/>
</svg></a> 
    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/user/900Seymour" className="link link-hover"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="w-5 h-5 lg:w-7 lg:h-7 fill-th-white">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
</svg></a> 
    <a target="_blank" rel="noreferrer" href="https://www.tripadvisor.com/Hotel_Review-g154943-d182519-Reviews-Moda_Hotel-Vancouver_British_Columbia.html" className="link link-hover"><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" className="w-5 h-5 lg:w-7 lg:h-7 fill-th-white">
      <path d="M 25 11 C 19.167969 11 13.84375 12.511719 9.789063 15 L 2 15 C 2 15 3.753906 17.152344 4.578125 19.578125 C 2.96875 21.621094 2 24.195313 2 27 C 2 33.628906 7.371094 39 14 39 C 17.496094 39 20.636719 37.492188 22.828125 35.105469 L 25 38 L 27.171875 35.105469 C 29.363281 37.492188 32.503906 39 36 39 C 42.628906 39 48 33.628906 48 27 C 48 24.195313 47.03125 21.621094 45.421875 19.578125 C 46.246094 17.152344 48 15 48 15 L 40.203125 15 C 36.148438 12.511719 30.828125 11 25 11 Z M 25 13 C 28.882813 13 32.585938 13.707031 35.800781 15.011719 C 30.964844 15.089844 26.824219 18.027344 25 22.214844 C 23.171875 18.019531 19.023438 15.078125 14.171875 15.011719 L 14.242188 14.988281 C 17.453125 13.699219 21.144531 13 25 13 Z M 14 17 C 19.523438 17 24 21.476563 24 27 C 24 32.523438 19.523438 37 14 37 C 8.476563 37 4 32.523438 4 27 C 4 21.476563 8.476563 17 14 17 Z M 36 17 C 41.523438 17 46 21.476563 46 27 C 46 32.523438 41.523438 37 36 37 C 30.476563 37 26 32.523438 26 27 C 26 21.476563 30.476563 17 36 17 Z M 14 21 C 10.6875 21 8 23.6875 8 27 C 8 30.3125 10.6875 33 14 33 C 17.3125 33 20 30.3125 20 27 C 20 23.6875 17.3125 21 14 21 Z M 36 21 C 32.6875 21 30 23.6875 30 27 C 30 30.3125 32.6875 33 36 33 C 39.3125 33 42 30.3125 42 27 C 42 23.6875 39.3125 21 36 21 Z M 14 23 C 16.210938 23 18 24.789063 18 27 C 18 29.210938 16.210938 31 14 31 C 11.789063 31 10 29.210938 10 27 C 10 24.789063 11.789063 23 14 23 Z M 36 23 C 38.210938 23 40 24.789063 40 27 C 40 29.210938 38.210938 31 36 31 C 33.789063 31 32 29.210938 32 27 C 32 24.789063 33.789063 23 36 23 Z M 14 25 C 12.894531 25 12 25.894531 12 27 C 12 28.105469 12.894531 29 14 29 C 15.105469 29 16 28.105469 16 27 C 16 25.894531 15.105469 25 14 25 Z M 36 25 C 34.894531 25 34 25.894531 34 27 C 34 28.105469 34.894531 29 36 29 C 37.105469 29 38 28.105469 38 27 C 38 25.894531 37.105469 25 36 25 Z"/></svg></a>
  </div> 
    </div>
  <div  className="mt-0 lg:mt-10 flex flex-col items-center lg:items-start gap-1">
    <span className="font-secondary text-th-white opacity-100 tracking-wider lg:tracking-widesttext-lg md:text-xl pb-1 lg:pb-3">EMAIL EXCLUSIVES</span> 
    <form action={`http://modahotel.us5.list-manage2.com/subscribe/post?u=7ceabcef4cc0e07a7f01ce034&id=c454c2b330`} method="post"
    id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" noValidate
    className="validate flex items-center flex-col md:flex-row"
    >
    <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Email address" className="input input-xs md:input-sm w-full max-w-xs rounded-none text-th-gray" />
    <input type="submit" value="Subscribe" className="btn btn-xs md:btn-sm font-normal bg-th-gray border-0 rounded-none font-primary py-2 md:py-3 text-th-white h-full" name="subscribe" id="mc-embedded-subscribe" />
    </form>
    {/* <a className="btn text-th-white bg-th-gray tracking-wide font-secondary btn-sm md:btn-md" aria-label="mailchimp" href="http://modahotel.us5.list-manage2.com/subscribe/post?u=7ceabcef4cc0e07a7f01ce034&id=c454c2b330">Subscribe</a> */}
    <p className="text-[11px] md:text-xs font-light leading-tight text-center md:text-left w-9/12 md:w-full">For Moda Hotel exclusive offers only.<br />Moda Hotel will not share your information with any other party.</p>
  </div>
  </div>
</div>
<SubFooter />
</footer>
  )
}
