import { motion } from "framer-motion"
import { HeroTitle } from "../../style/home.styled"


export const Hero = () => {
  const textanimation = {
    visible: { opacity: 1, scale: 1.2},
    hidden: { opacity: 0, scale: 1},
  }

  return (
    <>
    <div className="bg-home min-h-screen h-screen w-screen bg-center bg-cover bg-local lg:bg-fixed">
      <div className="h-full w-full flex justify-center items-center">
        <div className="mt-3 lg:mt-10 flex flex-col justify-center items-center text-center">
          <HeroTitle as={motion.h1} 
          transition={{ duration: 2, time:[0, 0, 1] }} 
          initial="hidden" animate="visible" 
          variants={textanimation}>MODA HOTEL</HeroTitle>
          <motion.p
           transition={{ duration: 3, time:[0, 0, 1] }} 
           initial="hidden" animate="visible" 
           variants={textanimation}
          className="font-primary tracking-widest font-light text-th-white text-center text-xs sm:text-sm md:text-xl lg:text-2xl xl:text-3xl"> - DOWNTOWN VANCOUVER - </motion.p>
        </div>
      </div>
    </div>

    </>
  )
}
