import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Wedding from "../components/events/Wedding"
import PrivateFunc from "../components/events/PrivateFunc"
import Pictures from "../components/events/Pictures"
import { EventsBg } from "../components/events/EventsBg"
import Header from '../components/SEO/Header'
import Meeting from "../components/events/Meeting"

const EventsPage = () => {
  return (
    <>
    <Header subtitle={"Events"} />
    <div className='overflow-hidden'>
    <div className="bg-th-gray min-h-screen w-screen">
    <Navbar />
    <div className={`bg-events w-screen h-[210px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>Events</h1>
    </div>
    <EventsBg>
    <Meeting />
    <Wedding />
    <PrivateFunc />
    <Pictures />
    </EventsBg>
    <Footer />
    </div>
    </div>
    </>
  )
}

export default EventsPage