import React from 'react'

const Card = ({service}) => {
  return (
    <div className='bg-th-gray border-l-4 md:border-l-8 border-th-secondary flex justify-center min-h-[120px] lg:min-h-[185px] items-center'>
        <div className='p-3 md:px-3 md:py-10 flex flex-col justify-center items-center space-y-1'>
        <img src={service[1]} alt={service[0]} className="w-10 h-10 md:w-14 md:h-14" />
        <p className='font-primary text-xs md:text-base lg:text-lg text-center pt-1'>{service[0]}</p>
        </div>
    </div>
  )
}

export default Card