import React from 'react'

const About2 = () => {
  return (
    <div className='w-screen flex flex-col lg:flex-row text-th-white py-10'>
      <div className='flex h-fit lg:h-[550px] mx-auto mb-5 lg:mb-0 w-full lg:w-1/2 space-x-0 xl:space-x-8 2xl:space-x-16 justify-center'>
      <img src="assets/images/about2_2.jpg" alt="img" className='w-1/2 lg:w-[280px] h-[220px] lg:h-[500px] object-cover self-end' />
      <img src="assets/images/about2_1.jpg" alt="img" className='w-1/2 lg:w-[280px] h-[220px] lg:h-[500px] object-cover self-start' />
      </div>
      <div className='flex flex-col justify-center space-y-4 lg:space-y-9 mx-auto w-11/12 lg:w-1/2'>
        <div className='p-1 lg:p-4 w-full lg:w-10/12 mx-auto 2xl:mx-0'>
          <h2 className='font-secondary text-xl lg:text-2xl mb-2 lg:mb-4 tracking-wide'>
          <span className='text-3xl lg:text-5xl'>H</span>ip and Historic — that, we are.
          </h2>
          <p className="text-sm lg:text-base font-primary">
          European-inspired and uber-chic in design, Moda creatively fuses elements of new-age artistic expression which emanates through its impressive exterior wall murals—by internationally renowned graffiti artists, Unintended Calculations—interlaced with its restored 1908 architecture from its historical heritage.
          </p>
        </div>
        <div className='p-1 lg:p-4 w-full lg:w-10/12 mx-auto 2xl:mx-0'>
        <h2 className='font-secondary text-xl lg:text-2xl mb-2 lg:mb-4 tracking-wide'>
          <span className='text-3xl lg:text-5xl'>D</span>esigned for modern comfort in a web 2.0 world
          </h2>
          <p className='text-sm lg:text-base font-primary'>Nestle and relax in a comforting homely room with all your essentials covered. At Moda, we understand the value of modern comfort. Not only does that include a cozy room and some luscious pillows, but the comfort of being connected. Moda Hotel offers free wireless internet and local calling to all our guests. Whether you’re an international or local guest, we believe in giving you the opportunity to stay connected—freely, openly, without thought of a price tag and all the time. Cheers.</p>
        </div>
      </div>
    </div>
  )
}

export default About2