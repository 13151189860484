import React from 'react'

const Divider = () => {
  return (
    <div className="divider w-2/3 lg:w-1/4 mx-auto">
        <img src='assets/images/dividerlogo.png' alt='divider-logo' className='w-8 h-8 lg:w-9 lg:h-9 object-cover mx-2' />
    </div>
  )
}

export default Divider