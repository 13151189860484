import React from 'react'

const Aboutmoda = () => {
  return (
    <div className="mt-9 mb-11 md:my-14 text-th-white">
    <h2 className='font-secondary text-th-white uppercase text-2xl lg:text-3xl xl:text-4xl tracking-wider text-center'>ABOUT MODA</h2>
    <div className='w-full lg:w-10/12 xl:w-8/12 2xl:w-7/12 mx-auto my-3 lg:my-16'>
    <h4 className='text-sm md:text-base lg:text-lg mx-2 lg:mx-0 font-secondary text-center tracking-normal lg:tracking-wide'>
    Glowing magnificently from the corner of 900 Seymour One-of-a-kind. Hip and Historic. Remarkable.
    </h4>
    <p className='w-11/12 lg:w-full font-primary pt-5 text-sm lg:text-base text-justify mx-auto'>
    Moda Hotel, a downtown Vancouver hotel, offering a unique hotel experience for people from all over the world to enjoy. With its hip and historic allure, Moda offers 69 chic guestrooms and suites and an inspiring collection of establishments including the award-winning Cibo Trattoria and Uva Wine Bar, the stylish Red Card Sports Bar and specialty beer and wine store, Viti Wine and Lagers.    </p>
    </div>
    </div>
  )
}

export default Aboutmoda