import React from 'react'

const ExploreHead = () => {
  return (
    <div className="my-10 lg:my-16 text-th-white">
    <h2 className='font-secondary text-th-white uppercase text-2xl xl:text-3xl tracking-wider text-center'>LOCAL ATTRACTIONS</h2>
    <div className='w-10/12 xl:w-8/12 2xl:w-7/12 mx-auto my-5 lg:my-10'>
    <p className='font-primary text-sm lg:text-base font-light'>
       Located in the vibrant Arts & Entertainment district, Moda is steps away from Vancouver's most popular hubs for urban dwellers—where socialites congregate, remarkable events aspire, Vancouver’s dining culture emancipates and arts and culture flourish. Within walking distance to many of Vancouver’s finest theatres, restaurants, museums, shopping areas, entertainment, and nightlife, Moda is ideal for travelers looking to experience Vancouver from its heart.</p>  
    </div>
    </div>
  )
}

export default ExploreHead