import React from 'react'
import { Link } from 'react-router-dom'

const AccommodationCard = ({data, isActive}) => {
  return (
<div className={`${isActive ? 'filter-none' : 'brightness-50' } w-full lg:w-5/6 max-h-fit mx-auto shadow-none bg-transparent cursor-grab active:cursor-grabbing`}>
    <img src={data.head_pic} alt={data.name} className={`w-full h-full max-h-[200px] lg:max-h-[500px] object-cover object-center`} />    
 <div className="relative w-full mx-auto left-0 top-0">
   <div className={` bg-th-white text-th-gray px-3 pt-6 pb-4 lg:px-6 lg:pt-7 lg:pb-5 bg-logo-card bg-no-repeat bg-right-bottom bg-[length:150px]`}>
    <h4 className="text-2xl lg:text-3xl tracking-wide font-secondary font-semibold uppercase leading-tight truncate">{data.name}  {data.sub_name}</h4>
  <div className="mt-3 lg:mt-4 font-primary text-sm leading-relaxed md:leading-none md:text-base lg:text-lg">
    {
    data.detail.length > 230 ? 
    data.detail.slice(0, 230) + ' ...'
    :
    data.detail  
    }
  </div>
  <div className="w-full mt-3 lg:mt-6 pr-2 lg:pr-5">
  <Link to={`/room/${data.id}`} className="tracking-wider text-base md:text-xl flex justify-center lg:justify-end items-center cursor-pointer font-secondary gap-0 ms:gap-2">
              LEARN MORE
              <svg
               className='w-10 h-2.5 md:w-10 md:h-4'
                viewBox="0 0 62 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M61.7034 9.5L53.0432 0.839746L44.3829 9.5L53.0432 18.1603L61.7034 9.5ZM0.919922 11H53.0432V8H0.919922V11Z"
                  fill="#C00000" 
                />
              </svg>
            </Link>
     </div>  
    </div>
 </div>
</div>


  )
}

export default AccommodationCard