import React from 'react'
import { Link } from 'react-router-dom'
const EventSection = () => {
return (
<div className='mt-20 lg:mt-48 pb-5 lg:pb-20 mb-20 md:mb-0 w-screen'>
<div className='bg-th-white h-[300px] md:h-[370px] lg:h-[450px] bg-event bg-no-repeat bg-cover bg-center lg:bg-right-center'>
<div className='relative bg-th-white w-full max-w-[650px] h-3/5 md:h-1/2 left-1/2 lg:left-1/3 xl:-translate-x-2/3 -translate-x-1/2 top-2/3 lg:w-1/3 lg:h-5/6 lg:top-1/2 lg:-translate-y-1/2 p-3 md:p-6 text-th-gray'>
<h2 className='font-secondary text-2xl md:text-3xl lg:text-4xl tracking-wider'>EVENTS</h2>
<div className='w-24 h-1 bg-th-secondary rounded-sm bg-opacity-75 mt-2 lg:mt-5'></div>
<p className='pt-3 lg:pt-4 mt-0 lg:mt-10 font-primary text-sm md:text-base'>The team at Moda Hotel Vancouver will be happy to work together with you in welcoming your guests and achieving your vision for your special time.</p>
<Link to="/events" className="pt-5 lg:pt-24 tracking-wide md:tracking-wider cursor-pointer font-secondary flex gap-1 lg:gap-3 items-center justify-end pr-1 md:pr-10">
              LEARN MORE
              <svg
                width="40"
                height="19"
                viewBox="0 0 62 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M61.7034 9.5L53.0432 0.839746L44.3829 9.5L53.0432 18.1603L61.7034 9.5ZM0.919922 11H53.0432V8H0.919922V11Z"
                  fill="#C00000" 
                />
              </svg>
            </Link>
</div>
</div>
</div>
  )
}

export default EventSection