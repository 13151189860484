import React from 'react'

const Subtitle = ({subtitle, direction}) => {
  return (
    <div className={`${direction==='right'? 'lg:justify-end' : 'lg:justify-start'} justify-center h-5 w-screen bg-th-primary bg-opacity-70 flex items-center`}>
        <h1 className={`${direction==='right'? 'lg:mr-12' : 'lg:ml-12'} ml-0 uppercase font-secondary text-th-white pb-0 lg:pb-1 text-3xl xl:text-[40px] tracking-wide lg:tracking-wider`}>{subtitle}</h1>
    </div>
  )
}

export default Subtitle