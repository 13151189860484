import React from 'react'

const SubTitle = ({title}) => {
  return (
    <div className='flex flex-col justify-center items-center gap-2 lg:gap-10'>
        <span className='w-2/3 lg:w-1/3 h-5 lg:h-7 bg-th-secondary bg-opacity-60'></span>
        <h2 className='font-secondary text-th-white uppercase text-3xl lg:text-4xl tracking-wider'>{title}</h2>
    </div>
  )
}

export default SubTitle