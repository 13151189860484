import styled from "styled-components";

export const HeroTitle = styled.h1`
text-align: center;
width: 100%;
white-space: nowrap;
font-family: 'Libre Bodoni';
font-weight: 400;
font-size: 80px;
line-height: 160px;
letter-spacing: 0.4em;
color: #FFFFFF;
text-shadow: 4px 4px 4px rgba(105, 103, 103, 0.64);
padding-left: 10px;
@media (max-width: 1440px) {
  font-size: 4em;
  line-height: 120px;
  }
  @media (max-width: 1024px) {
    font-size: 3.5em;
  }
@media (max-width: 768px) {
  padding: 0px 5px;
  font-size: 3em;
  letter-spacing: 0.2em;
  white-space: wrap;
  line-height: 55px;
  }
@media (max-width: 475px) {
  width: 95%;
  margin: 0 auto;
  font-size: 2.5em;
  letter-spacing: 0.1em;
  }
@media (max-width: 375px) {
  width: 95%;
  margin: 0 auto;
  font-size: 2.1em;
  letter-spacing: 0.1em;
  }
`
export const AboutImgBg = styled.div`
  width: 48%;
  max-width: 1000px;
  height: 45vh;
  margin: auto;
  background: url("assets/images/img_homepage02.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  left: 0;
  top: 50px;
  color: #fff;
  text-align: center;
  background-repeat: no-repeat, repeat;
  box-shadow: -60px -110px 0 rgba(155, 0, 0, 0.6);
  z-index: 0;
  @media (max-width: 1440px) {
    width: 45%;
    height: 45vh;
    max-height: 300px;
  }
  @media (max-width: 1024px) {
    width: 80%;
    height: 40vh;
    max-height: 300px;
    box-shadow: -60px -80px 0 rgba(155, 0, 0, 0.6);
    top: 10px;
  }
  @media (max-width: 768px) {
    width: 98%;
    height: 30vh;
    top: -35px;
    max-height: 150px;
    box-shadow: -100px -70px 0 rgba(155, 0, 0, 0.6);
  }
`;

