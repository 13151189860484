import React from 'react'
import data from '../../PrivateFunc'
import PrivateFunctionCard from './PrivateFunctionCard'

const PrivateFunc = () => {
  return (
    
    <section className= "pt-8 pb-14 md:py-20 text-th-white w-screen">
    <div className="flex flex-col-reverse md:flex-row justify-center items-center  space-x-0 md:space-x-5 lg:space-x-16 xl:space-x-28 ">
        <div className="w-11/12 md:w-1/2 mx-0 md:mx-auto pt-10 md:pt-0">
            <h3 className="font-secondary text-3xl md:text-4xl tracking-wider pb-5 text-center">PRIVATE FUNCTIONS</h3>
            <div className="bg-th-gray border border-th-white p-5 mb-10 lg:mb-20">
              <p className="font-primary text-sm md:text-base lg:text-lg font-light">To request a private event or dinner, please contact us and we will contact you within one business day to discuss possible options based on your ideal date, number of guests, and any special requests you may have.</p>
                <div className='py-5 font-primary font-light text-sm lg:text-base'>
                <p>- DINNER PARTIES + BRUNCHES & LUNCHEONS</p>
                <p>- MEETINGS</p>
                <p>- COCKTAIL PARTIES</p>
                </div>
                <h5 className=" font-secondary text-lg tracking-wider ">CONTACT</h5>
                <p className="font-primary font-light">For more information contact Kelly Jordan Hamilton at <a href="mailto: kellyjh@viaggiohospitality.com" className='link'>kellyjh@viaggiohospitality.com</a></p>
            </div>
          
    </div>
    </div>
       <div className='flex gap-8 lg:gap-2 flex-col lg:flex-row'>
    {
        data.map(e => (
            <PrivateFunctionCard key={e.id} props={e} />)
        )
    }
    </div>
    </section>

  )
}

export default PrivateFunc