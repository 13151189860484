import DinningCards from "./DinningCards"
import Subtitle from "./Subtitle"

const Dinning = () => {
  return (
    <div className="mt-2 lg:mt-36">
    <Subtitle subtitle={"DINING & BARS"} direction={"left"} />
    <div className="w-11/12 md:w-full xl:w-11/12 mx-auto max-w-screen-2xl my-14 md:my-28">
    <DinningCards />
    </div>
    </div>
  )
}

export default Dinning