import React from 'react'
import JumpBtn from './JumpBtn'

const AccessBtns = ({setCategory}) => {
  return (
    <div className='w-10/12 mx-auto py-5 lg:py-0 h-auto lg:h-[350px] flex flex-col justify-center items-center'>
    <div className='flex flex-col lg:flex-row mb-5 gap-5 lg:mb-16 space-x-0 lg:space-x-8 w-full justify-center items-center'>
       <button onClick={()=>setCategory('walk')} className='className="font-primary bg-th-secondary text-th-white tracking-normal lg:tracking-wide transition hover:bg-th-primary px-5 py-4 w-3/4 lg:w-56 h-14 lg:h-16 flex justify-center items-center text-center"'>WALKING DISTANCE</button>
       <button onClick={()=>setCategory('popular')} className='className="font-primary bg-th-secondary text-th-white tracking-normal lg:tracking-wide transition hover:bg-th-primary px-5 py-4 w-3/4 lg:w-56 h-14 lg:h-16 flex justify-center items-center text-center"'>POPULAR AREAS</button>
       <button onClick={()=>setCategory('landmark')} className='className="font-primary bg-th-secondary text-th-white tracking-normal lg:tracking-wide transition hover:bg-th-primary px-5 py-4 w-3/4 lg:w-56 h-14 lg:h-16 flex justify-center items-center text-center"'>LANDMARK ATTRACTIONS</button>
    </div>
    <div className='flex flex-col lg:flex-row mb-4 gap-5 lg:mb-0 space-x-0 lg:space-x-8 w-full justify-center items-center'>
       <button onClick={()=>setCategory('dining')} className='className="font-primary bg-th-secondary text-th-white tracking-normal lg:tracking-wide transition hover:bg-th-primary px-5 py-4 w-3/4 lg:w-56 h-14 lg:h-16 flex justify-center items-center text-center"'>DINING & BARS</button>
       <button onClick={()=>setCategory('other')} className='className="font-primary bg-th-secondary text-th-white tracking-normal lg:tracking-wide transition hover:bg-th-primary px-5 py-4 w-3/4 lg:w-56 h-14 lg:h-16 flex justify-center items-center text-center"'>OTHER EVENTS & CONCERT VENUES</button>
    </div>
    </div>
  )
}

export default AccessBtns