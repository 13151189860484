import React from 'react'

const Map = () => {
  return (
      <div className='mt-32 lg:mt-28 mb-28 w-screen h-[300px] lg:h-[500px]'>
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.838165901543!2d-123.12261488408622!3d49.27946367891923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486717e243f711b%3A0x105e9fc91383ade8!2sModa%20Hotel!5e0!3m2!1sen!2sca!4v1654891782662!5m2!1sen!2sca"
    className='w-full h-full grayscale'
    loading="lazy" referrerPolicy="no-referrer-when-downgrade"
    title="modahotelmap"
      ></iframe>

      </div>
  )
}

export default Map