import { useState, useCallback } from 'react';
import Gallery from "react-photo-gallery";
import photos from '../../photos.json'
import { motion } from 'framer-motion'

const Photos = ({selected}) => {
const showPhotos = photos.filter(photo => photo.category === selected)
const [currentImage, setCurrentImage] = useState(0);
const [viewerIsOpen, setViewerIsOpen] = useState(false);

const openLightbox = useCallback((event, { photo }) => {
  setCurrentImage(photo.src);
  setViewerIsOpen(true);
}, []);

const closeLightbox = () => {
  setViewerIsOpen(false);
}; 

  return (
     <div className='my-7 lg:my-16'>
        <div className='w-11/12 lg:w-10/12 mx-auto'>
          <Gallery 
            photos={selected === "all" ? photos : showPhotos}
            onClick={openLightbox}  
            margin={4}
            />
        </div>
    {viewerIsOpen ? 
    <div>
        <div onClick={closeLightbox} className="bg-th-black bg-opacity-70 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <button
                className="absolute -top-14 -right-5 lg:-top-10 lg:-right-24 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={closeLightbox}>
             <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 lg:h-9 lg:w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
              </button>
          <div className="border-0 text-th-white relative flex flex-col w-full outline-none focus:outline-none">
          <motion.img src={currentImage}
            initial={{scale: 0.9}}
            animate={{scale: 1}}
            transition={{ ease: "easeOut", duration: .2 }}
          alt="gallery_pic" className=' max-h-[500px] lg:max-h-[700px] max-w-[340px] lg:max-w-[1000px] object-cover' />
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
         : <></>}
    </div>

  )
}

export default Photos