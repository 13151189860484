import React from 'react'

const Phone = () => {
  return (
    <div className='border border-th-white text-th-white w-full lg:w-80 h-70 md:h-56 lg:h-80 flex flex-col md:flex-row lg:flex-col justify-center lg:justify-start space-x-0 md:space-x-10 lg:space-x-0 space-y-2 items-center py-5 md:py-8 px-10'>
    <svg xmlns="http://www.w3.org/2000/svg" className='fill-th-white bg-th-secondary w-16 h-16 md:w-20 md:h-20 p-4' viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
    </svg>
    <div className='pt-3 md:pt-0 lg:pt-7 text-center'>
    <h3 className='font-secondary text-xl md:text-3xl uppercase tracking-wider lg:tracking-wide'>Phone</h3>
    <a href='tel:604-683-4251' className='font-primary link text-sm md:text-base'>+1 604-683-4251
    </a>
    <h3 className='pt-1 font-secondary text-xl md:text-3xl uppercase tracking-wider lg:tracking-wide mt-2'>Fax</h3>
    <p className='font-primary text-sm md:text-base'>+1 604-683-0611</p>
    </div>
</div>
  )
}

export default Phone