import React from 'react'
import SubTitle from './SubTitle'

const Suites = () => {
  return (
    <div className="pt-24 mb-10 lg:pt-16 text-th-white">
    <SubTitle title={'suites'} />

    </div>
  )
}

export default Suites