import styled from 'styled-components'

export const EventsBg = styled.div`
    background: linear-gradient(90deg, #3E3E3E 0%, #3E3E3E 40%, #9B0000 40%, #9B0000 60%, #3E3E3E 60%, #3E3E3E 100%);
    
  @media (max-width: 768px) {
    background: linear-gradient(90deg, #3E3E3E 0%, #3E3E3E 30%, #9B0000 30%, #9B0000 70%, #3E3E3E 70%, #3E3E3E 100%);

  }
`
