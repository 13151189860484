import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const NavbarHome = () => {
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState(false)

  const handleScroll = () => {
    window.scrollY > 150 ? setScroll(true) : setScroll(false)
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll); 
    return () => window.removeEventListener("scroll", handleScroll);
  })

  return ( 
    // navigation
    <>
    <nav className="font-primary font-light fixed z-50 top-0 transition-all">
      <input id="my-drawer" onClick={()=> setOpen((prev)=>!prev)} type="checkbox" className="drawer-toggle" />
      <div className={`ease-in duration-300 flex justify-between w-full items-center ${scroll ? "pb-0 pt-0 h-0 md:h-20 bg-transparent lg:bg-th-black/50 " : "pb-1 pt-1 h-16 md:h-28 bg-transparent"} px-0 md:px-3 md:pb-2  md:pt-3 fixed`}>
        <label htmlFor="my-drawer" className="drawer-button w-36 lg:w-/ pl-3 cursor-pointer">
      {!open &&
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={` ${scroll ? 'h-0 w-0 lg:h-9 lg:w-9' :'h-8 lg:h-9 w-8 lg:w-9'} stroke-th-white`}
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 8h16M4 16h16"
            />
          </svg>
        }
        </label>
        <Link to="/" className="fixed left-1/2 -translate-x-1/2">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className={`ease-in duration-300 ${scroll ? "h-0 w-0 lg:h-20 lg:w-20 mt-0 lg:mt-0" :"mt-3 lg:mt-5 h-20 w-20 lg:h-32 lg:w-32"} transition-all`}
          />
        </Link>
        <div className={`mr-5 hidden flex-row w-1/3 ${scroll ? ' lg:flex' :'lg:hidden'} justify-end space-x-10 text-th-white font-primary tracking-wider`}>
         <div className="flex items-center gap-2 text-sm">
         <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
      </svg>
          <p> 604.683.4251</p>
         </div>
         <a href="https://goo.gl/maps/V47P9Djsg2bWJ3Z3A" className="link-hover flex items-center gap-2 text-sm" target="_blank" rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>View Map</a>
          <a
          className={`hidden lg:block`}
            href="https://www.bookonthenet.net/west/premium/eresmain.aspx?id=DLmeYQXq1ZTSiFAru9Uls9iPM7oJAxYC%2bQFIA4LgCDU%3d"
          >
          <button className="btn pt-1 font-normal bg-th-secondary border-0 tracking-wider text-th-white hover:bg-th-primary rounded-none">
            BOOK NOW
          </button>
        </a>
        </div>
        <a
         className={`hidden ${scroll ?'lg:hidden' :'lg:block'} mr-5`}
          href="https://www.bookonthenet.net/west/premium/eresmain.aspx?id=DLmeYQXq1ZTSiFAru9Uls9iPM7oJAxYC%2bQFIA4LgCDU%3d"
        >
          <button className="btn pt-1 font-normal bg-th-secondary border-0 tracking-wider text-th-white hover:bg-th-primary rounded-none">
            BOOK NOW
          </button>
        </a>
      </div>
      <div className="drawer-side">
       <label htmlFor="my-drawer" className="drawer-overlay"></label>
      <div className="fixed menu p-0 md:p-4 overflow-y-auto w-full md:w-1/2 h-full bg-th-black bg-opacity-90 md:bg-opacity-80 text-th-white uppercase text-sm md:text-lg">
        <label htmlFor="my-drawer" className="drawer-button cursor-pointer pl-3 md:pl-4 pt-4 md:pt-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 stroke-th-white cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
          </label>
          <ul className="h-full flex flex-col justify-around md:justify-center items-center gap-0 md:gap-2 pt-1.5 pb-10 md:py-0">
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to='/' className="tracking-widest hover:tracking-wider transition-all duration-300 py-2 md:py-2">HOME</Link>
          </li>
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to='/about' className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2">ABOUT US</Link>
          </li>
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to="/accommodations" className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2">ACCOMMODATION</Link>
          </li>
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2" to="/gallery">GALLERY</Link>
          </li>   
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to='/events' className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2">EVENTS</Link>
          </li>
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to='/services' className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2">SERVICES</Link>
          </li>
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to="/explore" className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2">EXPLORE</Link>
          </li>
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to="/history" className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2">HISTORY</Link>
          </li>
          <li className="flex justify-center items-center text-sm md:text-base">
            <Link to="/contact" className="tracking-widest hover:tracking-wider transition-all duration-300 py-2.5 md:py-2">CONTACT </Link>
          </li>
            <a className="my-3 flex justify-center items-center hover:bg-none" href="https://www.bookonthenet.net/west/premium/eresmain.aspx?id=DLmeYQXq1ZTSiFAru9Uls9iPM7oJAxYC%2bQFIA4LgCDU%3d">
              <button className="px-5 py-3 md:py-2.5 text-sm md:text-base bg-th-primary border-0 tracking-wider text-th-white hover:bg-th-secondary rounded-none">
                BOOK NOW
              </button>
            </a>
          <div className="w-full flex flex-col items-center gap-2 justify-center pt-2 pr-4 md:pr-3 md:pt-5 tracking-wider link">
          <a href="tel:604-683-4251" className="flex items-center gap-2"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 fill-th-secondary" viewBox="0 0 20 20">
          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
        </svg>604.683.4251</a>
          <a href="https://goo.gl/maps/V47P9Djsg2bWJ3Z3A" className="capitalize link-hover flex items-center gap-2" target="_blank" rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-th-secondary stroke-th-white"  viewBox="0 0 24 24" strokeWidth={1}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>View Map</a>
            </div>
          </ul>
        </div>
      </div>
    </nav>
    {/* //Scroll to top */}
    {scroll &&
    <div className="min-w-screen min-h-screen h-full w-full absolute block lg:hidden">
    <button onClick={scrollToTop} className="fixed z-20 bottom-3 right-3 text-th-white p-3.5 bg-th-gray/50 rounded-full border">
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    className="w-4 h-4"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
    ></path>
  </svg>
  </button>
    </div>}
    </>
  );
};
