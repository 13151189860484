import DinningCard from './DinningCard'
import dinningData from '../../dinningData.json'

const DinningCards = () => {
  return (
    <div className='flex gap-8 lg:gap-2 flex-col lg:flex-row'>
    {
        dinningData.map(data => (
            <DinningCard key={data.id} props={data} />)
        )
    }
    </div>
  )
}

export default DinningCards