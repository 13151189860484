import React, {useState} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import ExploreCard from './ExploreCard'
import { Pagination } from "swiper";
import others from '../../explore.json/others'

const Others = () => {
    const [open, setOpen] = useState({
        open: false,
        img:"",
        title:"",
        sub:"",
        disc:""
    })
  return (
      <>
    <div id='other' className='my-16 lg:my-28 w-screen'>
        <h2 className='font-secondary text-th-white uppercase text-center text-2xl lg:text-3xl tracking-wider mb-10 lg:mb-20'>OTHER EVENTS & CONCERT VENUES
        </h2>
        <div className='relative w-full lg:w-11/12 xl:w-9/12 mx-auto'>
        <Swiper
            slidesPerView={3}
            spaceBetween={0}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            modules={[Pagination]}
            className="mySwiper"
            navigation={{
              prevEl: '.prev-arrow',
              nextEl: '.next-arrow',
              clickable: true,
            }}
            breakpoints= {{
            0: {
              slidesPerView: 1
            },
            769: {
              slidesPerView: 2
            },
            1000: {
              slidesPerView: 3
            }
            }}
      >
        {others.map(data => (
             <SwiperSlide key={data.title} className="cursor-grab"><ExploreCard props={data} setOpen={setOpen} /></SwiperSlide>
        ))}
        </Swiper>
        <div className="prev-arrow absolute top-1/2 left-0 xl:-left-5 2xl:left-1 z-10 text-th-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 prev-arrow cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
        </svg></div>
        <div className="next-arrow absolute top-1/2 right-0 xl:-right-5 2xl:right-1 z-10 text-th-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg></div>
        </div>
    </div>
    {open.open &&
    <div className="overflow-hidden fixed top-0 right-0 left-0 z-50 w-screen h-screen min-h-screen min-w-screen bg-th-black bg-opacity-40">
    <div className="relative bg-th-gray text-th-white border border-th-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-11/12 h-auto md:w-[750px] md:h-[700px] lg:w-[900px] lg:h-[550px]">
            <div className="w-full h-full flex items-center justify-between space-x-1 lg:space-x-3 md:flex-row flex-col">
            <img src={open.img} alt="img" className='w-full md:w-1/2 h-2/5 max-h-[200px] md:max-h-fit md:h-full object-cover object-center' />
                <div className='w-full md:w-1/2 h-full flex items-center flex-col justify-start p-2 md:p-6'>
                <button type="button" onClick={() => setOpen({open:false})} className="hidden md:inline-flex ml-auto bg-transparent rounded-full text-sm p-1.5 items-center border hover:bg-th-black hover:bg-opacity-40">
                    <svg className="w-5 h-5 fill-th-white" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                </button>
                <div className='py-1 lg:py-5 h-full flex flex-col justify-between'>
                <div>
                <p className="text-xl md:text-2xl font-secondary tracking-wide mt-0 md:mt-10 lg:mt-0 mb-1 md:mb-7">
                  {open.title}
                </p>
                <p className="text-sm md:text-base mb-2 md:mb-4 font-primary font-light tracking-wide">
                    {open.sub}
                </p>
                <p className="text-sm md:text-base font-light font-primary leading-normal md:leading-relaxed">
                    {open.disc}
                </p>
                  </div>
                <div className='flex justify-end m-2 pt-3 md:pt-1 gap-3'>
                <a href={open.url} target="_blank" rel="noreferrer" className={`${open.url==='' ? "line-through	" : "" }inline-flex bg-transparent rounded-full font-primary font-light text-xs px-5 py-2 tracking-wider items-center border hover:bg-th-black hover:bg-opacity-40`}>
                    View Website
                </a>
                <button type="button" onClick={() => setOpen({open:false})} className="inline-flex md:hidden bg-transparent rounded-full font-primary font-light text-xs px-5 py-2 tracking-wider items-center border hover:bg-th-black hover:bg-opacity-40">
                    CLOSE
                </button>
                </div>
                </div>
                </div>
            </div>
        </div>
        </div>
        }
        </>
    )
    }

export default Others