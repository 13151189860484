
const Wedding = () => {
  return (

    <section className="py-12 md:py-20 text-th-white w-screen">
    <div className="flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-5 lg:space-x-16 xl:space-x-28 ">
        <img src="/assets/images/wedding.jpg" alt="wedding_img" className="w-full md:w-1/2 h-[300px] md:h-[500px] object-cover" />
        <div className="w-11/12 md:w-1/2 mx-0 md:mx-auto pt-10 md:pt-0 pr-0 lg:pr-20 xl:pr-40">
            <h3 className="font-secondary text-3xl md:text-4xl tracking-wider pb-5 text-center md:text-left">WEDDINGS</h3>
            <div className=" bg-th-gray border border-th-white p-5">
                <p className=" font-primary text-sm md:text-base lg:text-lg font-light">Your wedding day is one of the most significant days of your life and one that will be remembered forever. At Moda Hotel Vancouver, we want to make your wedding planning experience as simple as possible. To help you with the planning process, we offer customized options and benefits to help you plan your special day.</p>
                <h5 className=" font-secondary text-lg tracking-wider pt-5">CONTACT</h5>
                <p className="font-primary font-light">By phone: General Manager<a href="tel:604-683-4251"> 604-683-4251</a></p>
            </div>
        </div>
    </div>
    </section>

  )
}

export default Wedding