import React from 'react'
import AccommodationSlider from './AccommodationSlider'
import Subtitle from './Subtitle'

const Accommodation = () => {
  return (
    <div className='lg:pt-48 pt-8'>
    <Subtitle subtitle={"Accommodations"} direction={"right"} />
    <AccommodationSlider />
    </div>
  )
}

export default Accommodation