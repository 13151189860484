import React from 'react'
import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Header from '../components/SEO/Header'


const History = () => {
  return (
    <>
    <Header subtitle={"History"} />
    <div className="overflow-hidden">
    <div className="bg-th-gray min-h-screen w-screen">
    <Navbar />
    <div className=' text-th-white'>
    <div className={`bg-history w-screen h-[210px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>Moda History</h1>
    </div>
    <section className='w-screen flex flex-col lg:flex-row justify-center items-center space-x-10'>
        <div className='w-full lg:w-1/2'>
        <div className="bg-th-primary w-/9/12 lg:w-2/3 text-right mt-10 py-7">
            <h2 className='font-secondary tracking-wide md:tracking-widest text-xl md:text-2xl pr-5'>Historically-speaking</h2>
        </div>
        <div className='font-primary py-6 px-3 lg:p-10'>
            <p className='font-light text-sm lg:text-base leading-tight lg:leading-nomal'>With over a century of time under its belt, Moda Hotel Vancouver is a one of Vancouver's few heritage hotels remaining in downtown Vancouver.
                <br/>
                <br/>
                Once upon a long time ago, this Vancouver heritage building was envisioned in 1899 by two respected Vancouver businessmen: Architect John Edmeston Parr and a Speculative Developer Thomas Arthur Fee. These well-acclaimed Vancouverites also built other landmark historical Vancouver buildings such as the Vancouver Block, this Hotel Europe, Dunsmuir Hotel, and one of Vancouver's first large apartment blocks, the Manhattan Apartments.
                <br/>
                <br/>
                The heritage building was finally completed in 1908 and became known as the Dufferin Hotel—prestigiously named after Lord Dufferin (1826-1902), a former Governor General of Canada.
                <br/>
                <br/>
                Over the century, the hotel evolved and its concept changed. It underwent renovations—where the original crown moldings and façade of the building were covered and hidden under new walls and design. Once shining with sheer brilliance and pride, near the end of the 20th century, the Dufferin Hotel had lost its lack-lustre until a new vision for the heritage building enveloped.
                <br />
                <br />
                Moda Hotel Vancouver's owner, a self-made Italian entrepreneur and Vancouver real-estate developer envisioned a better future for the property—something Italian-inspired with elements of fresh design and style, but still connected to its historical roots; something unique to Vancouver - something people from all the world could enjoy. And so, the restoration process began.</p>
        </div>
        </div>
        <div className='hidden lg:block w-1/2 mt-16 pr-16'>
            <div className='grid space-y-5 lg:space-y-14 justify-items-stretch'>
            <img src='assets/images/history1.jpg' alt="history_img" className='w-[500px] h-[220px] object-cover justify-self-start' />
            <img src='assets/images/history2.jpg' alt='history_img2' className='w-[500px] h-[220px] object-cover justify-self-end' />
            </div>
        </div>
    </section>
    <section className='my-2 lg:my-20'>
        <img src="assets/images/history5.jpg" alt="history_picture1" className='w-screen h-[210px] object-cover' />
    </section>
    <section className='my-10 lg:my-0'>
        <div className='w-full flex justify-end mb-10 lg:mb-20'>
        <div className=' bg-th-primary w-full lg:w-1/3 text-left mt-5 lg:mt-10 py-7'>
            <h2 className='font-secondary tracking-widest text-lg lg:text-2xl pl-5'>RESTORATION</h2>
        </div>
        </div>
        <div className='w-11/12 lg:w-9/12 mx-auto'>
            <h3 className='text-center font-secondary tracking-wide lg:tracking-wider text-lg lg:text-xl mb-7'>We value our connection to a historical Vancouver while embracing a changing one.</h3>
            <p className=' font-primary font-light text-sm md:text-base leading-tight lg:leading-nomal '>
                Through our dynamic restoration process, this Moda Hotel Vancouver is still glowing brilliantly, a century later. Inspired by an ever-growing transformative Vancouver landscape, Moda Hotel Vancouver uniquely captures the essence of an evolving Vancouver through design and style—where 1908 historical permanence meets transformative creativity.
                <br/>
                <br/>
                A historically-infused ground floor
                Fusing contemporary design and progressive style with its original building structure, the design concept behind Moda Hotel Vancouver is unlike any other building in Vancouver. This Vancouver hotel possesses its original architecture, structure and construction which have been upgraded to today's standards and beautifully wrapped in urban-chic décor.
                <br/>
                <br/>
                Highlighted in the lobby, you'll find the original 100-year old exposed mosaic tile floors, restored crown mouldings and elegant wall mouldings. Throughout the building, you will discover other historical elements such as 80 yr. old hardwood floors in select rooms, and even the exterior cornice moulding and brick façade is original for all of Vancouver to enjoy. We have also kept Moda Hotel Vancouver's grandfather elevator in good working condition for guests to experience – a step back in time. In and throughout Moda Hotel Vancouver, you'll also find an elegant yet informal melange of marble and slate textures, as well as the original piping visible for a modern twist.
                <br/>
                <br/>
                Moda Hotel Vancouver's establishments, Uva Wine & Cocktail Bar, Cibo Trattoria and Red Card Sports Bar, also include some original structure elements such as the 200-year old Douglas fir tree beams and walls, exposed brick walls and 100-year old exposed tile or terrazzo flooring to compliment the chic interior design.
                <br/>
                <br/>
                Modernly-chic rooms
                With red accents and a range of neutrals and moody grey, Moda Hotel Vancouver has 69 guestrooms and suites that have been refurbished with a modern contemporary influence. View guestrooms and suites.
                <br/>
                <br/>
                Progressively-stylish exteriors
                Pushing the boundaries with artistic style and flair is something Moda Hotel Vancouver embraces. With great passion for the arts and cultural scene, Moda Hotel Vancouver commissioned four internationally renown artists to paint two collaborative murals on its exterior walls in March 2011. Curated by Indigo, Unintended Calculations (the name for the mural project and following gallery exhibition at Becker Galleries on Granville Island) united four very different approaches to the art form, Abstraction, by bringing together these diverse and unique artists. From Los Angeles, Augustine Kofie creates architecturally inspired works in his signature Vintage Futurism style, while Portland native Jerry Inscoe is influenced by Deconstructivist architecture. From London, Remi /Rough balances freedom and restraint in his antiform style, while Vancouverite Scott Sueme's mixed-media paintings demonstrate energetic movement. Bringing this calibre of individual artists together to work collaboratively was unheard of and revolutionary in the graffiti culture. Setting new heights for innovative street art expression in Vancouver, Moda Hotel Vancouver has brought vibrancy to the streets of Seymour and Smithe and has proudly worked to share this arts project and experience with the community. View the murals.
                <br/>
                <br/>
                Historically-connected and ultra-modern, experience Moda Hotel Vancouver for a truly memorable experience 
            </p>
        </div>
    </section>
    <section className='my-10 lg:my-28'>
        <div className='grid justify-items-stretch grid-rows-2 grid-cols-2 lg:grid-rows-1 lg:grid-cols-4'>
            <img src="assets/images/history2-1.jpg" alt="historypage_pic" className='h-40 lg:h-80 object-cover' />
            <img src="assets/images/history2-2.jpg" alt="historypage_pic" className='h-40 lg:h-80 object-cover' />
            <img src="assets/images/history2-3.jpg" alt="historypage_pic" className='h-40 lg:h-80 object-cover object-bottom' />
            <img src="assets/images/history2-4.jpg" alt="historypage_pic" className='h-40 lg:h-80 object-cover' />
        </div>
    </section>
    </div>
    <Footer />
    </div>
    </div>
    </>
  )
}

export default History