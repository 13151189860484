import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Photos from "../components/gallery/Photos"
import photos from '../photos.json'
import { useCallback, useState } from "react"
import Header from '../components/SEO/Header'

const Gallery = () => {
    const [selected, setSelected] = useState('all')

    const handleCategory = useCallback((e) => {
        setSelected(e.target.value)
    }, [])
    const photoCategories = photos.map(photo => photo.category)
    const categories = Array.from(new Set( photoCategories))

  return (
    <>
    <Header subtitle={"Gallery"} />
    <div className='overflow-hidden'>
    <div className="bg-th-gray min-h-screen w-screen">
    <Navbar />
    <div className={`bg-gallery w-screen h-[240px] md:h-[260px] lg:h-[430px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <div className="w-full h-full flex justify-end items-bottom flex-col space-y-3 lg:space-y-10 pb-4 lg:pb-10">
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center pt-28 lg:pt-40 tracking-wider'>Gallery</h1>
        <div className="flex justify-center">
        <select value={selected} onChange={handleCategory} className="select select-bordered select-sm lg:select-md w-full max-w-xs text-nomal lg:text-lg font-secondary tracking-wider bg-th-black bg-opacity-30 border border-r-th-white text-th-white capitalize">
        <option selected className="text-th-gray bg-th-white" value={"all"} >All</option>
        {categories.map(category => (
        <option key={category} value={category} className="text-th-gray capitalize bg-th-white">{category}</option>
        ))}
        </select>
        </div>
        </div>
    </div>
    <Photos selected={selected} />
    <Footer />
    </div>
    </div>
    </>
  )
}

export default Gallery