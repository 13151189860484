import React from 'react'
import Adress from './cards/Adress'
import EMail from './cards/EMail'
import Phone from './cards/Phone'
import Sales from './cards/Sales'

const ContactCards = () => {
  return (
    <>
    <div className='w-11/12 md:w-9/12 mx-auto flex flex-col space-y-5 lg:flex-row items-end justify-around my-10 md:my-20'>
        <Adress />
        <Phone />
        <EMail />
    </div>
    <div className='w-11/12 2xl:w-full mx-auto flex flex-col lg:flex-row space-y-5 lg:space-y-0 space-x-0 lg:space-x-5 2xl:space-x-0 items-center justify-evenly mt-20 md:mt-28 mb-20'>

    <Sales />
    </div>
    </>
  )
}

export default ContactCards