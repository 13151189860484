import React from 'react'

const About3 = () => {
  return (
    <div className='mt-20 mb-12 lg:my-32 text-th-white w-full lg:w-11/12 flex flex-col-reverse lg:flex-row justify-center items-center'>
      <div className='flex flex-col justify-center items-center space-y-9 w-full lg:w-1/2'>
        <div className='p-1 lg:p-4 w-11/12 lg:w-10/12'>
          <h2 className='font-secondary text-xl mt-4 lg:mt-0 lg:text-2xl mb-2 lg:mb-4 tracking-wide'>
          <span className='text-4xl lg:text-5xl'>W</span>ith a passion for food and drink
          </h2>
          <p className="font-primary text-sm lg:text-base">
          All our guests are invited to indulge in our establishments. Whether you're looking to treat yourself to a delightful breakfast or specialty coffee, lounge at an award winning wine bar, dine at an Italian Trattoria, catch a game at Vancouver's only upscale sports bar, or relax to some speciality wine and craft beer in your room, you’re options are within steps from your guestroom. Enjoy an intriguing meeting place to impress your clients or host a private dinner in Cibo Trattoria’s Madonna Room.          </p>
        </div>
        </div>
        
        <div className='w-screen lg:w-1/2 flex items-center justify-center'>
          <div className="flex flex-col w-1/2 soace-y-0 lg:space-y-10">
          <img src="assets/images/dinning/uva.jpg" alt="cibo" className="w-fill h-[125px] lg:h-fit object-cover lg:w-10/12" />
          <img src="assets/images/dinning/redcard.jpg" alt="cibo" className="w-full h-[125px] lg:h-fit object-cover lg:w-10/12" />
          </div>
          <img src="assets/images/dinning/cibo.jpg" alt="cibo" className="w-1/2 lg:w-1/3 object-cover h-[250px] lg:h-[500px]" />
        </div> 
    </div>
  )
}

export default About3