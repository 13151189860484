import React from 'react'

const EMail = () => {
  return (
    <div className='border border-th-white text-th-white w-full lg:w-80 h-76 md:h-56 lg:h-80 flex flex-col md:flex-row lg:flex-col justify-center lg:justify-start space-x-0 md:space-x-10 lg:space-x-0 space-y-2 items-center py-8 md:py-10 px-10'>
    <svg xmlns="http://www.w3.org/2000/svg" className='fill-th-white bg-th-secondary w-16 h-16 md:w-20 md:h-20  p-4' viewBox="0 0 16 16">
  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
  </svg>
  <div className='pt-5 md:pt-0 lg:pt-7 text-center'>
      <h3 className='font-secondary text-xl md:text-3xl uppercase tracking-wider lg:tracking-wide pb-2'>E-MAIL</h3>
      <a href='mailto:info@modahotel.ca' className='link font-primary text-center text-sm md:text-base'>info@modahotel.ca
      </a>
  </div>
</div>
  )
}

export default EMail